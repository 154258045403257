<template>
  <div class="leave-time">
    <v-row>
      <v-col cols="8" sm="6">
        <h1 class="main-color my-4">{{ $t("Leave Time") }}</h1>
      </v-col>
      <v-col
        cols="4"
        sm="6"
        :class="currentAppLocale == 'en' ? 'text-right' : 'text-align-left'"
      >
        <v-btn
          icon
          class="my-4"
          @click="edit = true"
          v-if="!edit"
          :title="$t('Edit')"
        >
          <v-icon large>edit</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <p class="error--text text-center" v-if="validationMsg">
      <strong>
        {{ validationMsg }}
      </strong>
    </p>
    <v-simple-table class="simple-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t("Grades") }}</th>
            <th v-for="day in weekDays" :key="day.id" class="text-left">
              {{ $t(day.day) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(grade, gradeIndex) in gradeLeaveTimes" :key="gradeIndex">
            <td>{{ grade.name }}</td>
            <td v-for="(time, index) in grade.times" :key="index">
              <span v-if="!edit">
                {{ time.time_24 }}
              </span>
              <timePickerComponent
                v-else
                :title="$t('Leave Time')"
                :gradeIndex="gradeIndex"
                :index="index"
                :timeVal="time.time"
                v-on:changed="saveTime"
              ></timePickerComponent>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row v-if="edit">
      <v-col
        :class="currentAppLocale == 'en' ? 'text-right' : 'text-align-left'"
        class="my-4"
      >
        <v-btn class="main-btn mx-4" @click="save" :title="$t('save')">{{
          $t("Save")
        }}</v-btn>
        <v-btn class="modal-btn-cancel" @click="cancel">{{
          $t("Cancel")
        }}</v-btn>
      </v-col>
    </v-row>
    <SnackComponent
      :snackbar="snackbar"
      :snackColor="snackColor"
      :snackMsg="snackMsg"
      @closeSnack="closeSnack"
    ></SnackComponent>
  </div>
</template>

<script>
import timePickerComponent from "@/components/timePickerComponent";
import axios from "axios";
import SnackComponent from "../../components/snackComponent.vue";

export default {
  name: "leaveTime",
  components: { timePickerComponent, SnackComponent },
  data() {
    return {
      snackbar: false,
      snackColor: "green",
      snackMsg: "",
      validationMsg: "",
      edit: false,
      weekDays: [],
      gradeLeaveTimes: [],
    };
  },
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    cancel() {
      this.validationMsg = "";
      this.edit = false;
      this.getGradeLeaveTimes();
    },
    validateInputs() {
      let validationValid = true;
      this.gradeLeaveTimes.forEach((grade) => {
        grade.times.forEach((time) => {
          if (time.time == "") {
            validationValid = false;
          }
        });
      });

      return validationValid;
    },
    save() {
      if (this.validateInputs()) {
        axios
          .post(
            this.getApiUrl + "/nedaa/saveGradesLeaveTimes",
            this.gradeLeaveTimes,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.edit = false;
              this.snackbar = true;
              this.snackMsg = this.$i18n.t("Data Saved Successfully");
              this.getGradeLeaveTimes();
            }
          });
      } else {
        this.snackbar = true;
        this.snackColor = "error";
        this.snackMsg = this.$i18n.t("You must fill in all the fields");
      }

      setTimeout(() => {
        this.snackbar = false;
      }, 2000);
    },
    saveTime(title, time, gradeIndex, index) {
      this.gradeLeaveTimes[gradeIndex].times[index].time = time;
    },
    getWeekdays() {
      axios
        .get(this.getApiUrl + "/nedaa/getWeekDayes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.weekDays = response.data.data;
        });
    },
    getGradeLeaveTimes() {
      axios
        .get(this.getApiUrl + "/nedaa/getGradesLeaveTimes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.gradeLeaveTimes = response.data.data;
        });
    },
  },
  mounted() {
    this.getWeekdays();
    this.getGradeLeaveTimes();
    $("#scrollUp").remove();
  },
};
</script>

<style lang="css" scoped>
.text-align-left {
  text-align: left !important;
}
</style>
